<template>
    <v-container fluid class="wrapper px-4 py-0" :style="cssVars">
        <v-row class="top">
            <TopBar></TopBar>
        </v-row>
        <v-row align="stretch" class="center">
            <div class="navigation pa-0"
                 v-if="!hideNavigation">
                <Navigation></Navigation>
            </div>
            <div class="navigation pa-0"
                 v-if="superadmin">
                <SuperAdminNavigation></SuperAdminNavigation>
            </div>
            <div class="content"
                :class="{'adaptive-width': hideNavigation}">
                <slot name="main"></slot>
            </div>
        </v-row>
    </v-container>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { fullNavbarWidth, miniNavbarWidth, navbarHeight } from '@/constants/navbar';
import SuperAdminNavigation from '@/components/superadmin/SuperAdminNavigation';
import Navigation from '@/components/widgets/Navigation';
import TopBar from '@/components/widgets/TopBar';

export default {
    name: 'Page',
    components: { Navigation, SuperAdminNavigation, TopBar },
    props: {
        hideNavigation: {
            type: Boolean,
            'default': false
        },
        superadmin: {
            type: Boolean,
            'default': false
        }
    },
    computed: {
        ...mapFields('navigation', [
            'isMenuOpened'
        ]),
        cssVars () {
            return {
                '--nav_width': this.isMenuOpened ? fullNavbarWidth : miniNavbarWidth,
                '--nav_height': navbarHeight
            };
        }
    }
};
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";

    .wrapper {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
    .top {
        flex: 0 1 auto;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 3;
        margin: 0;
    }

    .navigation {
        z-index: 6;
    }

    .center {
        margin: 0 -16px;
    }

    .content {
        width: calc(100% - #{var(--nav_width)});
        margin-top: var(--nav_height);
        margin-left: var(--nav_width);
        transition: margin-left 0.3s, width 0.3s;
    }

    .adaptive-width {
        width: 100%;
    }

    .toggle {
        position: absolute;
        top: 0;
        right: -24px;
        border: 1px solid $grey_lighten_2;
        border-left: none;
        border-top: none;
        z-index: 6;
        background: #fff;
        border-radius: 0 4px 4px 0;
        cursor: pointer;
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
        .navigation {
            width: 0;
            position: initial;
            height: auto;
            z-index: 5;
        }

        .content {
            width: 100%;
            margin-left: 0;
        }

        ::v-deep {
            & .v-navigation-drawer__prepend {
                background-color: $light_grey;
            }
        }

        .adaptive-width {
            width: 100%;
            margin-left: 0;
        }
    }
</style>
