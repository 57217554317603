<template>
    <li class="sidebar__item"
        :style="cssVars"
        ref="sidebarItem"
        data-test="flyOutActivator"
        :data-parent="parent.dataTest"
        v-if="parent.visibility"
        @mouseenter="showFlyOutMenu($event)"
        @mouseleave="hideFlyOutMenu($event)">
        <div class="sidebar__activator"
             data-test="submenuActivator"
             :class="sidebarActivatorClass"
             @click="toggleSubmenu">
            <router-link :to="getUrl">
            <v-icon size="18"
                    class="icon"
                    :class="{'icon_active': sidebarActivatorClass === 'sidebar__activator_active'}">
                {{ parent.icon }}</v-icon>
            <span class="sidebar__title ml-4" :class="{'sidebar__title_hidden': miniVariant, 'sidebar__title_hasNew': parent.hasNew}">{{ parent.title }}</span>
            </router-link>
        </div>
        <ul class="sidebar__submenu"
            :style="transformStyle"
            v-if="hasSubmenu"
            ref="submenu"
            data-test="submenu"
            :class="sidebarSubmenuClass">
            <li v-if="isFlyOut && miniVariant">
                <h4 class="font-weight-medium px-3 sidebar__submenu-title"
                    @click="navigateToPage">{{ parent.title }}</h4>
                <v-divider class="divider" v-if="parentHasChildren"></v-divider>
            </li>
            <li v-if="parentHasChildren">
                <ul>
                    <MenuSubitem v-for="(child, i) in parent.children"
                                 :key="i"
                                 @redirect="handleRedirect"
                                 :child="child"></MenuSubitem>
                </ul>
            </li>
        </ul>
    </li>
</template>

<script>
import { navbarHeight } from '@/constants/navbar';
import { mapFields } from 'vuex-map-fields';
import MenuSubitem from '@/components/menu/MenuSubitem';

export default {
    name: 'MenuItem',
    components: { MenuSubitem },
    props: {
        parent: {
            type: Object,
            required: true
        },
        miniVariant: {
            type: Boolean,
            required: true
        },
        navbarWidth: {
            type: String,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data: () => ({
        isFlyOut: false,
        isActive: false,
        borderWidth: 1,
        submenuHeight: 0,
        mouseXPosition: null
    }),
    computed: {
        ...mapFields('navigation', [
            'activeParentIndex',
            'isMenuOpened'
        ]),
        getUrl () {
            return Object.prototype.hasOwnProperty.call(this.parent, 'route') ? { name: this.parent.route } : { name: this.parent.children[0].route };
        },
        cssVars () {
            return {
                '--nav_height': navbarHeight
            };
        },
        isItemActive () {
            if (Object.prototype.hasOwnProperty.call(this.parent, 'route')) {
                return this.$route.name === this.parent.route;
            }
            return this.$route.fullPath === this.parent.path || (this.parent.children && this.parent.children.find(el => el.route === this.$route.name));
        },
        parentHasChildren () {
            return Object.prototype.hasOwnProperty.call(this.parent, 'children');
        },
        transformStyle () {
            if (this.isFlyOut && this.hasSubmenu) {
                let offsetY;
                const viewportHeight = window.innerHeight;
                const positionTop = this.$refs.sidebarItem.getBoundingClientRect().top;
                const positionBottom = this.$refs.sidebarItem.getBoundingClientRect().bottom;
                const _navbarHeight = parseInt(navbarHeight);

                if ((positionTop > (viewportHeight - _navbarHeight) / 2) &&
                    (this.submenuHeight > (viewportHeight - _navbarHeight - positionTop))) {
                    offsetY = positionBottom - this.submenuHeight - _navbarHeight;
                } else {
                    offsetY = positionTop - _navbarHeight;
                }

                return `transform: translate3d(${this.navbarWidth}, ${offsetY}px, 0); opacity: 1;`;
            }
            return null;
        },
        sidebarSubmenuClass () {
            if ((this.isItemActive && this.parentHasChildren) || (this.isActive && this.parentHasChildren)) {
                return 'sidebar__submenu_active';
            } else if (this.isFlyOut) {
                return 'sidebar__submenu_fly-out';
            }
            return null;
        },
        sidebarActivatorClass () {
            let sidebarClass = this.parent.hasNew ? 'sidebar__activator_hasNew' : '';
            if (this.isItemActive ||
                this.isActive ||
                (this.miniVariant && this.index === this.activeParentIndex)) {
                sidebarClass += ' sidebar__activator_active';
            } else if (this.isFlyOut) {
                sidebarClass += ' sidebar__activator_fly-out';
            }
            return sidebarClass;
        },
        isMobile () {
            return this.$vuetify.breakpoint.smAndDown;
        },
        hasSubmenu () {
            return this.parentHasChildren || this.miniVariant;
        }
    },
    watch: {
        miniVariant: {
            handler (val) {
                if (val) {
                    this.isActive = false;
                } else {
                    if (this.activeParentIndex === this.index) {
                        this.isActive = true;
                    }
                }
            },
            immediate: true
        },
        activeParentIndex: {
            handler (val) {
                if ((val === this.index) && !this.miniVariant) {
                    this.isActive = true;
                } else if ((val !== this.index) && !this.miniVariant) {
                    this.isActive = false;
                }
            },
            immediate: true
        }
    },
    methods: {
        showFlyOutMenu (event) {
            if (!(this.isActive || (this.isItemActive && this.parentHasChildren)) && !this.isMobile) {
                this.mouseXPosition = event.x;
                this.isFlyOut = true;
                this.$nextTick(() => {
                    if (this.hasSubmenu) {
                        this.submenuHeight = this.$refs.submenu.offsetHeight;
                    }
                });
            }
        },
        hideFlyOutMenu (event) {
            if (!event) return;
            const xDifference = this.mouseXPosition > event.x;
            if (!event.toElement ||
                event.toElement.className !== 'sidebar__wrapper' ||
                (event.toElement.className === 'sidebar__wrapper' && xDifference)) {
                this.isFlyOut = false;
                this.mouseXPosition = null;
            }
        },
        getRoute (item) {
            return Object.prototype.hasOwnProperty.call(item, 'route') ? { name: item.route } : { path: item.path };
        },
        toggleSubmenu () {
            this.isFlyOut = false;
            this.activeParentIndex = this.index;
            if (this.parentHasChildren) {
                if (!this.isActive) {
                    if (!this.miniVariant) {
                        this.isActive = true;
                    }
                    this.navigateToFirstChild();
                }
            } else {
                this.navigateToParent();
            }
        },
        handleRedirect () {
            this.activeParentIndex = this.index;
            if (this.isFlyOut) {
                this.hideFlyOutMenu();
            }
            if (this.isMobile) {
                this.isMenuOpened = false;
            }
        },
        navigateToFirstChild () {
            const child = this.parent.children.find(el => el.visibility === true);
            if (this.$router.currentRoute.name === child.route) return;
            this.$router.push(this.getRoute(child));
        },
        navigateToParent () {
            if (this.$router.currentRoute.name === this.parent.route) return;
            this.$router.push(this.getRoute(this.parent));
        },
        navigateToPage () {
            if (this.parentHasChildren) {
                this.navigateToFirstChild();
            } else {
                this.navigateToParent();
            }
        }
    }
};
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";
    @import "@/assets/css/_mixins.scss";

    .sidebar {
        &__item {
            white-space: nowrap;
        }
        &__activator {
            @include navbar_item_title;
            cursor: pointer;
            &_active {
                color: $secondary;
                font-weight: 600;
                background-color: $medium_grey;
                border-color: $secondary;
                & .v-icon {
                    color: $secondary;
                }
            }
            &_hasNew {
                color: $red;
                background-color: $light_red;
                border-color: $red;
                animation-name: hasNew;
                animation-duration: 3s;
                animation-iteration-count: infinite;
                & .v-icon {
                    color: $red;
                }
            }
            &_fly-out{
                background-color: $medium_grey;
            }
        }
        &__title {
            transition: width 0.3s;
            margin-top: 2px;
            &_hidden {
                width: 1px;
                overflow: hidden;
            }
            &_hasNew {
                color: $red;
            }
        }
        &__submenu {
            padding-left: 0;
            padding-bottom: 8px;
            display: none;
            transition: display 0.5s;
            background-color: #fff;
            &-title {
                @include navbar_item_title;
                padding-top: 9px;
                padding-bottom: 9px;
                cursor: pointer;
                &:hover {
                    background-color: $medium_grey;
                }
            }
            & ul {
                padding-left: 0;
            }
            & li {
                list-style: none;
            }
            &_active {
                background-color: $medium_grey;
                border-left: 4px solid $secondary;
                display: block;
            }
            &_fly-out {
                display: block;
                opacity: 0;
                position: fixed;
                top: 0;
                left: 0;
                min-width: 200px;
                margin-top: -1px;
                padding: 2px 0;
                background-color: #fff;
                border: 1px solid #eee;
                border-left: 0;
                border-radius: 0 3px 3px 0;
                max-height: calc(100vh - #{var(--nav_height)});
                overflow-y: auto;
                & .sidebar__submenu-item {
                    padding-left: 15px;
                    font-size: $xs;
                }
            }
        }
    }

    .icon {
        width: 24px;
        padding: 0 3px;
        &_active {
            margin-left: -4px;
        }
    }

    .divider {
        margin-top: 2px;
    }

    @keyframes hasNew {
      from {background-color: $light_red;}
      to {background-color: transparent;}
    }
</style>
