<template>
    <li class="sidebar__submenu-item"
        v-if="child.visibility"
        :class="{'sidebar__submenu-item_active': isItemActive, 'pb-0': child.divider}"
        :data-test="identifier"
        :data-child="child.dataTest"
        @click="redirectToPage">

        <router-link :to="getUrl">
            <p class="mb-0"
               :class="{'sidebar__submenu-item_bordered pb-2': child.divider}">{{ child.title }}</p>
        </router-link>

    </li>
</template>

<script>
export default {
    name: 'MenuSubitem',
    props: {
        child: {
            type: Object,
            required: true
        }
    },
    computed: {
        isItemActive () {
            if (Object.prototype.hasOwnProperty.call(this.child, 'route')) {
                return this.$route.name === this.child.route;
            }
            return this.$route.fullPath === this.child.path;
        },
        identifier () {
            return Object.hasOwnProperty.call(this.child, 'route') ? this.child.route : null;
        },
        getUrl () {
            return Object.prototype.hasOwnProperty.call(this.child, 'route') ? { name: this.child.route } : { path: this.child.path };
        }
    },
    methods: {
        redirectToPage () {
            this.$emit('redirect');
        }
    }
};
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";
    .sidebar {
        &__submenu {
            &-item {
                list-style: none;
                padding: 8px 8px 8px 52px;
                cursor: pointer;
                white-space: normal;
                &_active {
                    background-color: $medium_grey;
                    color: $secondary;
                    font-weight: 600;
                }
                &_bordered {
                    border-bottom: 1px solid $grey_lighten_2;
                }
            }
        }
    }
</style>
