<template>
    <div class="sidebar"
         :class="{'sidebar_closed': isMobile && !isMenuOpened}"
         :style="cssVars">
        <div class="sidebar__wrapper">
            <v-layout v-if="isMobile"
                      justify-end
                      class="mt-3 mr-3">
                <v-icon @click="closeMenu">{{ ICONS.CLOSE }}</v-icon>
            </v-layout>
            <ul class="sidebar__list">
                <MenuItem v-for="(parent, index) in menu"
                          :key="index"
                          :parent="parent"
                          :index="index"
                          :mini-variant="miniVariant"
                          :navbar-width="navbarWidth"></MenuItem>
                <li v-if="isMobile" class="sidebar__mobile-item">
                    <Logout :icon-size="18" :bold-text="false"></Logout>
                </li>
            </ul>
            <p class="collapse"
               v-if="!isMobile"
               @click="toggleMenu">
                <v-icon color="medium_grey">{{ collapseIcon }}</v-icon>
                <span v-if="!miniVariant">{{ $t('buttons.collapse_sidebar') }}</span>
            </p>
        </div>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { fullNavbarWidth, miniNavbarWidth, navbarHeight } from '@/constants/navbar';
import MenuItem from '@/components/menu/MenuItem';
import Logout from '@/components/auth/Logout';
import { ICONS } from '@/constants/icons';

export default {
    name: 'SuperAdminNavigation',
    components: { MenuItem, Logout },
    data: () => ({
        ICONS
    }),
    computed: {
        ...mapFields('navigation', [
            'isMenuOpened'
        ]),
        menu () {
            return [
                {
                    title: 'agencies',
                    icon: ICONS.ACCOUNT_GROUP,
                    visibility: true,
                    route: 'SuperAdminManageAgency'
                },
                {
                    title: 'users',
                    icon: ICONS.ACCOUNT,
                    visibility: true,
                    route: 'superAdminUsers'
                }
            ];
        },
        collapseIcon () {
            return this.isMenuOpened ? ICONS.DOUBLE_LEFT : ICONS.DOUBLE_RIGHT;
        },
        miniVariant () {
            return !this.isMenuOpened && !this.isMobile;
        },
        navbarWidth () {
            return this.isMenuOpened ? fullNavbarWidth : miniNavbarWidth;
        },
        cssVars () {
            return {
                '--nav_width': this.navbarWidth,
                '--nav_height': navbarHeight
            };
        },
        isMobile () {
            return this.$vuetify.breakpoint.smAndDown;
        }
    },
    watch: {
        miniVariant: {
            handler (val) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        window.sessionStorage.setItem('miniVariant', val);
                    }, 70);
                });
            },
            immediate: true
        }
    },
    methods: {
        toggleMenu () {
            this.isMenuOpened = !this.isMenuOpened;
        },
        closeMenu () {
            this.isMenuOpened = false;
        }
    },
    created () {
        if (!this.isMobile) {
            const _val = window.sessionStorage.getItem('miniVariant') === 'true';
            this.isMenuOpened = !_val;
        } else {
            this.isMenuOpened = false;
        }
    }
};
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";
    @import "@/assets/css/_mixins.scss";

    .full-height {
        min-height: 100%;
    }

    .sidebar {
        transition: width 0.3s, left 0.3s;
        position: fixed;
        width: var(--nav_width);
        top: calc(1px + #{var(--nav_height)});
        height: calc(100vh - #{var(--nav_height)});
        bottom: 0;
        left: 0;
        background-color: $light_grey;
        box-shadow: inset -1px 0 0 #dbdbdb;
        transform: translate3d(0, 0, 0);
        &__wrapper {
            overflow-y: auto;
            overflow-x: hidden;
            height: 100%;
        }
        &__list {
            padding-left: 0;
            list-style: none;
            margin-bottom: 60px;
        }
        &__mobile-item {
            @include navbar_item_title;
        }
    }

    .collapse {
        width: var(--nav_width);
        overflow: hidden;
        white-space: nowrap;
        border-top: 1px solid $grey_lighten_2;
        transition: width 0.3s;
        margin-bottom: 0;
        padding: 10px 16px;
        cursor: pointer;
        position: fixed;
        bottom: 0;
        background: $tertiary;
        display: flex;
        align-items: center;
        justify-content: left;
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
        .sidebar {
            width: 100%;
            top: 0;
            height: 100vh;
            overflow-y: auto;
            display: block;
            transition: display 0.3s;
            &_closed {
                display: none;
            }
            &__list {
                margin-bottom: 5px;
            }
        }
    }
</style>
