<template>
    <Page>
        <template slot="main">
            <router-view></router-view>
        </template>
    </Page>
</template>

<script>
import Page from '@/components/templates/Page';

export default {
    name: 'index',
    components: { Page }
};
</script>

<style scoped>

</style>
